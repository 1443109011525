<template>
    <item-wrapper>
           <item-header :title="t('delivery.title')" />
            <!-- <div class="header-details">
                <p>Free delivery to UK only. Ireland is £15. International delivery is £20.</p>
            </div> -->
        <main>
            <div>
                <h3>{{ t('delivery.orderDispatch') }}</h3>
                <p>{{ t('delivery.intro1') }}</p>
                <p>{{ t('delivery.intro2') }}</p>
            </div>
            <div>
                <h3>{{ t('delivery.deliveryDetails') }}</h3>
                <p>{{ t('delivery.intro3') }}</p>
            </div>
        </main>
    </item-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Delivery',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    :deep(.item-wrapper) {
        padding: 0;
        h2{
            margin-bottom: 60px
        }
    }

    // .header-details{
    //     @include flex-center();
    //     p{
    //         width: 1280px;
    //         padding: 11px 0 30px 0;
    //         font-size: 18px;
    //         font-weight: 400;
    //         color: $font-color;
    //         line-height: 36px;
    //         margin: 0;
    //         text-align: center;
    //         border-bottom: 2px solid #CECECE;
    //     }
    // }

    main{
        @include flex-center();
        flex-direction: column;
        margin-bottom: 100px;
        div{
            @include flex-center();
            width: 1280px;
            flex-direction: column;
            padding: 0 0 53px 0;
            h3{
                font-size: 20px;
                font-weight: 800;
                color: #000000;
                line-height: 24px;
                margin-bottom: 40px;
            }
            p{
                font-size: 18px;
                font-weight: 400;
                color: #545454;
                line-height: 36px;
                margin: 0;
            }
            &:nth-child(1){
                border-bottom: 2px solid #CECECE;
            }
            &:nth-child(2){
                padding-top: 45px;
            }
        }
    }
</style>
